<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="20px" pl="10px" pr="10px" pb="5px"> -->
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
  <CBox my="20px">
      <CGrid templateColumns="7fr 3fr" :gap="6">
        <CBox w="100%" bg="white" border="1px" borderColor="borderBox" h="min-content">
          <!-- <CBox d="flex" mx="32px" my="15px">
            <BreadcrumbUpload />
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" /> -->
          <!-- <CBox mx="32px" my="20px"> -->
            <CBox v-if="isLoading">
              <CBox h="20px" />
              <Loading />
            </CBox>
            <CBox v-if="!isLoading" mx="32px" my="20px">
              <CText fontWeight="bold" color="fontMain" my="10px">Tulis Keahlian yang kamu kuasai</CText>
              <CSimpleGrid :columns="3" :spacing="3" maxH="calc(100vh - 310px)" overflowY="scroll">
                <CBox border="1px" borderColor="borderBox" v-for="(item, counter) in form" :key="counter">
                  <CBox mx="10px" mt="10px" mb="20px">
                    <CBox d="flex" justifyContent="space-between">
                      <CText color="fontCountService">{{ counter+1 }}/6</CText>
                      <CBox d="flex">
                        <CImage v-if="!item.isEdit" class="pointer" @click.native="item.isEdit = true" :src="require('@/assets/icon/pencil-white.png')" w="15px" h="15px" />
                        <CImage v-if="!item.isEdit" class="pointer" @click.native="deleteService(counter)" :src="require('@/assets/icon/close-white.png')" w="15px" h="15px"  ml="5px"/>
                        <ButtonBlue v-if="item.isEdit" label="Simpan" size="sm" rounded="45px" @click.native="item.isEdit = false" />
                        <!-- <CImage v-if="item.isEdit" class="pointer" @click.native="item.isEdit = false" :src="require('@/assets/icon/check-circle-blue.png')" w="18px" h="18px" ml="5px" /> -->
                      </CBox>
                    </CBox>
                    <CBox mt="20px">
                      <CFormControl>
                        <CFormLabel color="activeMenu" fontSize="15px">Nama Keahlian</CFormLabel>
                        <CInput v-if="item.isEdit" type="text" placeholder="Nama Keahlian" v-model="item.name" />
                        <CText v-if="!item.isEdit" color="fontMain">{{ item.name }}</CText>
                      </CFormControl>
                      <CFormControl mt="10px">
                        <CFormLabel color="activeMenu" fontSize="15px">Deskripsi</CFormLabel>
                        <CTextarea v-if="item.isEdit" placeholder="Deskripsi" v-model="item.description" :value="item.description"></CTextarea>
                        <CText v-if="!item.isEdit" color="fontMain">{{ item.description }}</CText>
                      </CFormControl>
                      <CFormControl mt="10px">
                        <CFormLabel color="activeMenu" fontSize="15px">Tools / Alat yang digunakan</CFormLabel>
                        <CInput v-if="item.isEdit" type="text" placeholder="Tools / Alat yang digunakan" v-model="item.platform" />
                        <CText v-if="!item.isEdit" color="fontMain">{{ item.platform }}</CText>
                      </CFormControl>
                    </CBox>
                  </CBox>
                </CBox>
                <!-- <CBox border="1px" borderColor="borderBox">
                  <CBox mx="10px" mt="10px" mb="20px">
                    <CBox d="flex" justifyContent="space-between">
                      <CText color="fontCountService">1/6</CText>
                      <CBox d="flex">
                        <CImage :src="require('@/assets/icon/pencil-white.png')" w="15px" h="15px" />
                        <CImage :src="require('@/assets/icon/close-white.png')" w="15px" h="15px"  ml="5px"/>
                        <CImage :src="require('@/assets/icon/check-circle-blue.png')" w="18px" h="18px" ml="5px" />
                      </CBox>
                    </CBox>
                    <CBox mt="20px">
                      <CFormControl>
                        <CFormLabel color="activeMenu" fontSize="15px">Nama Service</CFormLabel>
                        <CText color="fontMain">Service</CText>
                      </CFormControl>
                      <CFormControl mt="15px">
                        <CFormLabel color="activeMenu" fontSize="15px">Deskripsi</CFormLabel>
                        <CText color="fontMain">Lorem ipsum dolor sit amet, consectetur adipiscing elit</CText>
                      </CFormControl>
                      <CFormControl mt="15px">
                        <CFormLabel color="activeMenu" fontSize="15px">Platform yang digunakan</CFormLabel>
                        <CText color="fontMain">Swift</CText>
                      </CFormControl>
                    </CBox>
                  </CBox>
                </CBox> -->
                <CBox border="1px" borderColor="borderBox" v-if="form.length < 6 && isAdd">
                  <CImage class="pointer" @click.native="addService()" :src="require('@/assets/img/add-service.png')" w="100%" h="full" objectFit="cover" />
                </CBox>
              </CSimpleGrid>
            </CBox>
            <CDivider v-if="!isLoading" borderColor="borderBox" border="1.5px" />
            <CBox v-if="!isLoading" align="right" m="20px">
              <ButtonBlue v-if="isAdd" label="Lanjutkan" @click.native="onSubmit()" :disabled="disabledButton" />
              <ButtonBlue v-else label="Lanjutkan" :disabled="true" />
            </CBox>
          <!-- </CBox> -->
        </CBox>
        <CBox>
          <CBox mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;">
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Keahlian</CText>
              <CText color="fontInformation" fontSize="14px">
                Kamu dapat menuliskan jasa atau keahlian yang dapat kamu lakukan disini. Sebagai contoh kamu bisa menuliskan Translate Indo - Eng pada file Microsoft Word atau Ilustrasi Wajah dengan Adobe Illustrator.
              </CText>
              <CImage :src="require('@/assets/img/service-ilustration.png')" />
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
      <!-- <WizardFooter @onSubmit="onSubmit()" :disabledButton="disabledButton" /> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_SERVICE, GET_SERVICE } from "@/store/auth.module"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
// import ButtonSecondary2 from "@/components/button/ButtonSecondary2.vue"
// import BreadcrumbUpload from "@/components/breadcrumb/BreadcrumbUpload.vue"
import Swal from "sweetalert2";
import Loading from "@/components/Loading.vue";
// import WizardFooter from "@/components/footer/WizardFooter.vue";

export default {
  metaInfo: {
    title: "Keahlian",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    ButtonBlue,
    // ButtonSecondary2,
    // BreadcrumbUpload,
    Loading,
    // WizardFooter
  },
  data() {
    return {
      isLoading: false,
      disabledButton2: false,
      form: []
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    isAdd() {
      let result = true;
      for (const d of this.form) {
        if (d.isEdit) {
          result = false;
          break;
        }
      }
      return result;
    },
    disabledButton() {
      if (this.disabledButton2) {
        return true;
      } else {
        let result = true;
        for (const d of this.form) {
          if (d.isEdit) {
            result = false;
            break;
          }
        }
        return !result;
      }
    }
  },
  methods: {
    async getData() {
      // setTimeout(async () => {
        this.isLoading = true;
        await this.$store.dispatch(GET_USER)
        await this.$store.dispatch(GET_SERVICE, this.currentUser.emp_id)
        const service = this.$store.getters.currentService
        let used = [];
        for (const d of service) {
          used.push({
            ...d,
            isEdit: false
          })
        }
        this.form = used
        this.isLoading = false;
      // }, 100);
    },
    addService() {
      this.form.push({
        name: "",
        description: "",
        platform: "",
        isEdit: true
      });
    },
    deleteService(id) {
      this.form.splice(id, 1);
    },
    onSubmit() {
      this.disabledButton2 = true;
      const used = [];
      for (const d of this.form) {
        used.push({
          name: d.name,
          description: d.description,
          platform: d.platform
        })
      }

      this.$store
        .dispatch(UPDATE_SERVICE, {
          id: this.currentUser.emp_id,
          data: {
            service: used
          }
        })
        .then(() => {
          this.disabledButton2 = false;
          this.$router.push({ name: "Config Complete" })
        })
        .catch((err) => {
          this.disabledButton2 = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
  }
}
</script>

<style scoped>
.coba {
  content: "";
  left: calc(-0.5rem);
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  display: block;
  width: 1rem;
  height: 1rem;
}
.pointer {
  cursor: pointer;
}
</style>
