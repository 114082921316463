import { render, staticRenderFns } from "./ConfigServices.vue?vue&type=template&id=62067339&scoped=true&"
import script from "./ConfigServices.vue?vue&type=script&lang=js&"
export * from "./ConfigServices.vue?vue&type=script&lang=js&"
import style0 from "./ConfigServices.vue?vue&type=style&index=0&id=62067339&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62067339",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CTextarea: require('@chakra-ui/vue').CTextarea, CSimpleGrid: require('@chakra-ui/vue').CSimpleGrid, CDivider: require('@chakra-ui/vue').CDivider, CGrid: require('@chakra-ui/vue').CGrid})
